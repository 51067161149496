var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pfg-content pfg-content--full"},[_c('div',{staticClass:"pfg-content_container"},[_c('div',{staticClass:"pfg-content_1-1"},[_c('header',{staticClass:"pfg-header"},[_c('div',{staticClass:"pfg-header_top"},[_c('div',{staticClass:"pfg-header_top_left"},[_c('div',{staticClass:"a_flex a_align-items_center"},[_c('h1',{staticClass:"a_margin-bottom-0"},[_vm._v("Gegevens")]),_c('pfg-loader',{attrs:{"loading":_vm.loading}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editing),expression:"!editing"}],staticClass:"pfg-header_top_right"},[_c('router-link',{staticClass:"button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10",attrs:{"to":{
								name: 'Member appointment create',
								params: { id: _vm.gebruikerStore.id },
							}}},[_c('span',{staticClass:"button-txt"},[_vm._v("Afspraak inplannen")])]),_c('button',{staticClass:"button v_brand_secondary v_has-icon-right v_smaller a_margin-left-10",on:{"click":_vm.toggleEditing}},[_c('span',{staticClass:"button-txt"},[_vm._v("Gegevens aanpassen")]),_c('pfg-icon',{attrs:{"icon-id":"icon_edit"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"}],staticClass:"pfg-header_top_right"},[_c('button',{staticClass:"button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10",on:{"click":_vm.cancelEdit}},[_c('span',{staticClass:"button-txt"},[_vm._v("Annuleren")])]),_c('button',{staticClass:"button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10",on:{"click":_vm.saveEdits}},[_c('span',{staticClass:"button-txt"},[_vm._v("Opslaan")])])])])]),_c('div',{staticClass:"pfg-content_tab-bar"},[_c('router-link',{staticClass:"pfg-content_tab-bar_link",attrs:{"to":{
						name: 'Gebruiker persoonlijk gegevens',
						params: { id: _vm.gebruikerStore.id },
					}}},[_vm._v("Persoonlijke gegevens")]),_c('router-link',{staticClass:"pfg-content_tab-bar_link",attrs:{"to":{
						name: 'Gebruiker persoonlijk medisch',
						params: { id: _vm.gebruikerStore.id },
					}}},[_vm._v("Medische gegevens")]),_c('router-link',{staticClass:"pfg-content_tab-bar_link",attrs:{"to":{
						name: 'Gebruiker persoonlijk abonnementsvorm',
						params: { id: _vm.gebruikerStore.id },
					}}},[_vm._v("Abonnementsvorm")])],1),_c('div',{staticClass:"a_padding-40 a_bg_brand_white a_default-border-radius"},[_vm._l((_vm.formData.items),function(item,i){return _c('span',{key:("user-personal-medical-lid-item-" + i)},[_c('div',{staticClass:"pfg-content_input"},[_c('div',{staticClass:"pfg-content_input_left v_switch-width"},[_c('pfg-switcher',{attrs:{"disabled":!_vm.editing,"switch-description":"Hartklachten?","switch-active":"Ja","switch-inactive":"Nee"},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1),_c('div',{staticClass:"pfg-content_input_right"},[_vm._v(" "+_vm._s(_vm.capitalize(item.slug ? _vm.descriptions[item.slug] || item.slug : item.slug))+" ")])]),(item.hasOwnProperty('extraText'))?_c('textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing || item.extraText),expression:"editing || item.extraText"},{name:"model",rawName:"v-model",value:(item.extraText),expression:"item.extraText"}],attrs:{"disabled":!_vm.editing || !item.enabled,"placeholder":"Zo ja, welke?"},domProps:{"value":(item.extraText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "extraText", $event.target.value)}}}):_vm._e(),(item.selectables)?_vm._l((item.selectables),function(selectable,j){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing || item.enabled),expression:"editing || item.enabled"}],key:("user-personal-medical-selectable-button-" + i + "-" + j),staticClass:"button v_brand_primary-lighter v_smallest a_margin-right-10",class:{
								'v_brand_primary-lighter': selectable.active,
								'v_ghost_brand_primary-lighter': !selectable.active,
							},attrs:{"disabled":!_vm.editing || !item.enabled},on:{"click":function($event){selectable.active = !selectable.active}}},[_c('span',{staticClass:"button-txt"},[_vm._v(_vm._s(_vm.capitalize(selectable.text)))])])}):_vm._e(),_c('hr',{staticClass:"v_dashed v_less-margin"})],2)}),_c('div',{staticClass:"pfg-content_input"},[_c('div',{staticClass:"pfg-content_input_left v_switch-width"},[_c('pfg-switcher',{attrs:{"switch-description":"Alle medische vragen zijn afgenomen?","switch-active":"Ja","switch-inactive":"Nee","disabled":!_vm.editing},model:{value:(_vm.formData.medicalCheck),callback:function ($$v) {_vm.$set(_vm.formData, "medicalCheck", $$v)},expression:"formData.medicalCheck"}})],1),_c('div',{staticClass:"pfg-content_input_right a_text_brand_primary"},[_vm._v(" Alle medische vragen zijn afgenomen ")])]),_c('hr'),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"}],staticClass:"button v_ghost_brand_primary v_smaller",on:{"click":_vm.cancelEdit}},[_c('span',{staticClass:"button-txt"},[_vm._v("Annuleren")])]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editing),expression:"editing"}],staticClass:"button v_brand_primary-lightest v_smaller a_margin-left-10",on:{"click":_vm.saveEdits}},[_c('span',{staticClass:"button-txt"},[_vm._v("Aanpassingen opslaan")])])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }