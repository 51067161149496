


























































































































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import type { MedicalItem } from "@/lib/backend/coach.api";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import type { MedicalData } from "@/services/types/coach";
import { capitalize } from "@/utils/capitalize";

type Data = {
	loading: boolean;
	editing: boolean;
	descriptions: Record<string, string>;
	formData: {
		medicalCheck: boolean;
		items: MedicalItem[];
	};
};

export default defineComponent({
	data(): Data {
		return {
			loading: false,
			editing: false,
			descriptions: {
				fysiotherapeut: "Wil je gebruik maken van een gratis consult bij onze fystiotherapeut?",
				overige: "Heb je andere klachten die wij zouden moeten weten?",
				oefeningen: "Zijn er oefeningen die je niet mag, kan of liever niet wilt doen?",
			},
			formData: {
				medicalCheck: false,
				items: [],
			},
		};
	},
	computed: {
		...mapStores(useGebruikerStore, usePopupStore),
	},
	watch: {
		"gebruikerStore.medical"(newValue: MedicalData) {
			this.formData.medicalCheck = newValue.medischCheck === 1;
			this.formData.items = newValue.items;
		},
	},
	async created() {
		await this.check();
	},
	async destroyed() {
		this.editing = false;
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getBasicInfo(); // Necessary for for modal missing info.
			await this.gebruikerStore.getMedical();
		},
		toggleEditing() {
			this.editing = !this.editing;
		},
		async cancelEdit() {
			this.editing = false;

			if (this.gebruikerStore.medical) {
				this.formData.medicalCheck = this.gebruikerStore.medical.medischCheck === 1;
				if (this.gebruikerStore.medical.items)
					this.formData.items = this.gebruikerStore.medical.items;
			}

			this.errors.clear();
		},
		async saveEdits() {
			const isValid = await this.$validator.validateAll();

			if (!isValid) return false;

			if (!this.formData.medicalCheck) {
				return this.popupStore.showError(
					'Zorg dat alle medische vragen zijn afgenomen en dat de checkbox bij "Alle medische vragen zijn afgenomen" op "Ja" staat.',
				);
			}

			await this.gebruikerStore.saveMedical({
				medischCheck: this.formData.medicalCheck ? 1 : 0,
				items: this.formData.items,
			});

			this.cancelEdit();
		},
		capitalize,
	},
});
